<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">SpookApp</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome to SpookApp! 👋🏻</p>
          <p class="mb-2">Forgot your password?</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details="auto"
              :rules="emailRules"
              class="mb-3"
            ></v-text-field>

            <v-btn block color="primary" class="mt-6" @click="submitForm"> Send instructions </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> Already have an account? </span>
          <router-link :to="{ name: 'pages-login' }"> Sign in instead </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth'

import { set } from 'firebase/database'

import { collection, addDoc } from 'firebase/firestore'
import { dbRef, forgotClick } from '../../../firebaseConfig'

const auth = getAuth()

export default {
  mounted: function () {
    // this.tabeRe()
    forgotClick()
  },
  methods: {
    submitForm() {
      const result = this.$refs.form.validate()
      if (result) {
        this.signInWithMail()
        console.log('result')
      } else {
        console.log('offline')
      }
    },
    async signInWithMail() {
      this.$store.commit('LOADER', true);
      let value, errorss
      // var user = firebase.auth().currentUser;
      //  console.log("before" + user.emailVerified);

      sendPasswordResetEmail(auth, this.email)
        .then(userCredential => {
          value = true
          this.swal(value, '')
        })
        .catch(error => {
          // Handle Errors here.
          // var errorCode = error.code;
          //var errorMessage = error.message;
          value = false

          console.log('error' + error)
          // console.log("errorCode" + errorCode);
          // console.log("errorMessage" + errorMessage);
          errorss = error.message
          this.swal(value, errorss)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })

      this.swal(value, errorss)
    },
    async swal(value, error) {
      //  console.log(constant.error.hello);
      if (value == true) {
        if (error == '' || error == undefined) {
          this.$swal.fire('success', 'Email send successfully', 'success')
        } else {
          this.$swal.fire('success', error, 'success')
        }
      } else if (value == null) {
        //  console.log("null");
      } else {
        // console.log("value get" + error);
        if (error == '') {
          //   console.log("null value get" + error);
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        } else {
          //  console.log("not null value get" + error);
          this.$swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
          })
        }
      }
    },
    async signInWithMailAuth() {
      let user = await this.signInWithMail()
      this.$store.commit('LOADER', true)

      if (localStorage) {
        localStorage.setItem('emailForSignIn', JSON.stringify(this.googleResult))
      }

      await this.rolesFunction(this.googleResult.user.uid)
      const txt = new Map()
      const roles = new Map()
      if (this.myDBuser == null) {
        roles.set('permission', 1)
      } else {
        roles.set('permission', this.myDBuser.roles)
      }
      txt.set('username', this.googleResult.user.displayName)
      txt.set('roles', Object.fromEntries(roles))
      console.log(Object.fromEntries(txt))
      console.log(JSON.stringify(txt))
      store.commit('change', Object.fromEntries(txt))
      router.push('home')
    },
  },
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    valid: false
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,
      valid: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 5 || 'Password must be more than 4 characters',
        emailMatch: () => "The email and password you entered don't match",
      },
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
